import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import StepsHeader from '../components/StepsHeader';
import PackageBanner from '../components/PackageBanner';
import { STEPS_CHECKOUT, STORAGE_KEYS } from '../resources/constants';
import CardForm from '../components/CardForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { navigate } from 'gatsby';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const CreditCard = () => {
  const [packageData, setPackageData] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA))
      navigate('/next-steps');
    else setPackageData(true);
  }, []);

  if (!packageData) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
      width="100%"
      mb={5}
      mt={12}
    >
      <StepsHeader title="Checkout" currentStep="2" steps={STEPS_CHECKOUT} />
      <Box my={2} width={1}>
        <PackageBanner />
      </Box>
      <Elements stripe={stripePromise}>
        <CardForm />
      </Elements>
    </Flex>
  );
};

export default CreditCard;
