import React from 'react';
import Layout from '../components/Layout';
import CreditCard from '../sections/CreditCard';

const CreditCardPage = () => (
  <Layout>
    <CreditCard />
  </Layout>
);

export default CreditCardPage;
