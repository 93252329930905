import React from 'react';
import { Flex, Image, Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import StyledRectangle from '../components/StyledRectangle';

import aonLogo from '../../assets/images/logo@1.5x.svg';

const StyledHeader = styled(Text)`
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #161f26;
`;

const StyledSteps = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  text-align: right;
  color: #8b97a2;
`;

const StyledCurrentStep = styled(Box)`
  width: 12px;
  height: 6px;
  border-radius: 3px;
  background-color: #090f13;
  margin-left: 5px;
`;

const StyledPendingStep = styled(Box)`
  width: 12px;
  height: 6px;
  border-radius: 3px;
  background-color: #dbe2e7;
  margin-left: 5px;
`;

const StyledCompleteStep = styled(Box)`
  width: 12px;
  height: 6px;
  border-radius: 3px;
  background-color: #ff5e63;
  margin-left: 5px;
`;

const StepsHeader = ({ title, currentStep, steps }) => {
  return (
    <Box
      width={['100%', '88%', '70%', '65%']}
      sx={{
        position: 'fixed',
        top: '0',
        zIndex: '1'
      }}
    >
      <StyledRectangle bg="white" px={2}>
        <Flex justifyContent="space-between">
          <Box justifyContent="flex-start" px={2} py={2}>
            <StyledHeader>{title}</StyledHeader>
            <StyledSteps pt={1}>
              Step {currentStep}/{Object.keys(steps).length}
            </StyledSteps>
          </Box>
          <Box justifyContent="flex-end" px={2} py={2}>
            <Image src={aonLogo} alt="logo" height="25px"></Image>
            <Flex flexWrap="wrap" justifyContent="flex-end" bg="white" pt={1}>
              {Object.keys(steps).map(element =>
                currentStep == steps[element] ? (
                  <StyledCurrentStep key={steps[element]}></StyledCurrentStep>
                ) : currentStep < steps[element] ? (
                  <StyledPendingStep key={steps[element]}></StyledPendingStep>
                ) : (
                  <StyledCompleteStep key={steps[element]}></StyledCompleteStep>
                )
              )}
            </Flex>
          </Box>
        </Flex>
      </StyledRectangle>
    </Box>
  );
};

export default StepsHeader;
