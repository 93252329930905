import React, { useState } from 'react';
import { Flex, Box, Button } from 'rebass/styled-components';
import { navigate } from 'gatsby';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import Line from '../components/Line';
import { Label, Input } from '@rebass/forms';
import Modal from '../components/Modal';
import InputBox from '../components/InputBox';
import styled from 'styled-components';
import { confirmPatientPaymentService } from '../services/services';
import { STORAGE_KEYS } from '../resources/constants';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import FadeLoader from 'react-spinners/FadeLoader';

const StyledBox = styled(Box)`
  border-radius: 4px;
  border: solid 1px #f0f5f6;
  background-color: #ffffff;
  height: 60px;
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #8b97a2;
`;

const CardForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [email, setEmail] = useState('');

  const [cardFields, setCardFields] = useState({
    number: '',
    exp: '',
    cvc: ''
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    setIsProcessing(true);
    setMessage('');

    let number = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: number
    });

    if (error) {
      setMessage(error.message);
      setShowModal(true);
      setIsProcessing(false);
      return;
    }

    if (paymentMethod) {
      let packageData = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA)
      );

      const data = {
        packageId: packageData.contentful_id,
        paymentMethodId: paymentMethod.id,
        nameOnCard: nameOnCard,
        email: email
      };

      confirmPatientPaymentService(data)
        .then(response => {
          console.log('response');
          console.log(response);
          if (response.status == '200') {
            navigate(`/confirm-payment`);
          }
        })
        .catch(error => {
          setMessage(error.response.data.message);
          setShowModal(true);
          setIsProcessing(false);
          return;
        });
    }
  };

  const back = () => {
    navigate(`/payment-method`);
  };

  const cardNumberOptions = {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.31',
        letterSpacing: '0.4px',
        color: '#161f26',
        '::placeholder': {
          fontWeight: 'normal',
          lineHeight: '1.31',
          letterSpacing: 'normal',
          color: '#8b97a2'
        }
      }
    },
    placeholder: 'Credit Card Number'
  };

  const cardExpiryOptions = {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.31',
        letterSpacing: '0.4px',
        color: '#161f26',
        '::placeholder': {
          fontWeight: 'normal',
          lineHeight: '1.31',
          letterSpacing: 'normal',
          color: '#8b97a2'
        }
      }
    },
    placeholder: 'Exp (MM/YY)'
  };

  const cardCVCOptions = {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.31',
        letterSpacing: '0.4px',
        color: '#161f26',
        '::placeholder': {
          fontWeight: 'normal',
          lineHeight: '1.31',
          letterSpacing: 'normal',
          color: '#8b97a2'
        }
      }
    },
    placeholder: 'CVC'
  };

  const stripeElementChange = (element, field) => {
    if (!element.empty) setCardFields({ ...cardFields, [field]: true });
    else setCardFields({ ...cardFields, [field]: false });
  };

  return (
    <Box as="form" id="CreditCardForm" onSubmit={handleSubmit} width={1}>
      <StyledRectangle bg="white" py={5} width={1}>
        <Box px={5}>
          <CardSubtitle>Credit Card</CardSubtitle>
          <Line height="3px" color="red" my={1} />
          <Flex flexWrap="wrap" pt={5}>
            <InputBox
              label="Name on Card"
              placeholder="Name on Card"
              value={nameOnCard}
              setValue={setNameOnCard}
              required="required"
              type="text"
              filter="onlyLetters"
            />
          </Flex>
          <Flex flexWrap="wrap" pt={5}>
            <InputBox
              label="Email"
              placeholder="Email"
              value={email}
              setValue={setEmail}
              required="required"
              type="email"
            />
          </Flex>
          <Flex flexWrap="wrap" pt={7}>
            <StyledBox width={[1]} px={5} py={3}>
              {cardFields.number && (
                <StyledLabel>Credit Card Number</StyledLabel>
              )}
              <CardNumberElement
                options={cardNumberOptions}
                onChange={element => stripeElementChange(element, 'number')}
              />
            </StyledBox>
          </Flex>
          <Flex flexWrap="wrap" my={6}>
            <StyledBox width={[6 / 13]} px={5} py={3}>
              {cardFields.exp && <StyledLabel>Exp</StyledLabel>}
              <CardExpiryElement
                options={cardExpiryOptions}
                onChange={element => stripeElementChange(element, 'exp')}
              />
            </StyledBox>
            <Box width={[1 / 13]}></Box>
            <StyledBox width={[6 / 13]} px={5} py={3}>
              {cardFields.cvc && <StyledLabel>CVC</StyledLabel>}
              <CardCvcElement
                options={cardCVCOptions}
                onChange={element => stripeElementChange(element, 'cvc')}
              />
            </StyledBox>
          </Flex>
        </Box>
      </StyledRectangle>
      {isProcessing && (
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          my={5}
        >
          <FadeLoader
            height={15}
            width={5}
            radius={2}
            color={'#ff5e63'}
            loading="true"
          />
        </Flex>
      )}
      <Flex
        justifyContent="space-between"
        width={1}
        px={10}
        pt={5}
        sx={{
          paddingBottom: '100px'
        }}
      >
        <Button
          variant="bWhite"
          width="87px"
          disabled={isProcessing}
          onClick={() => back()}
        >
          Back
        </Button>
        <Button
          variant="bDarkRed"
          width="87px"
          name="pay"
          disabled={isProcessing}
        >
          Pay
        </Button>
      </Flex>
      {showModal && (
        <Modal
          description={message}
          textButton="Ok"
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </Box>
  );
};

export default CardForm;
