import React from 'react';
import Rodal from 'rodal';
import { Text, Flex, Button } from 'rebass/styled-components';
import styled from 'styled-components';
import 'rodal/lib/rodal.css';

const ModalText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #31373b;
`;

const Modal = ({ description, textButton, showModal, setShowModal }) => {
  return (
    <Rodal visible={showModal} onClose={() => setShowModal(false)} width={300}>
      <ModalText my={45} textAlign="center">
        {description}
      </ModalText>
      <Flex justifyContent="space-around">
        <Button
          variant="bDarkRed"
          width="82px"
          onClick={() => {
            setShowModal(false);
          }}
        >
          {textButton}
        </Button>
      </Flex>
    </Rodal>
  );
};

export default Modal;
