import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Image } from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import styled from 'styled-components';
import { STORAGE_KEYS } from '../resources/constants';

const PackageText = styled(Text)`
  font-weight: 600;
  line-height: 1.13;
  color: #ffffff;
`;

const PackageBanner = () => {
  const [data, setData] = useState('');

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA)) {
      let packageData = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA)
      );
      setData(packageData);
    }
  }, []);

  if (!data) return null;
  return (
    <StyledRectangle bg="red" py={5} px={2}>
      <Flex alignItems="center" alignContent="center" justifyContent="center">
        <Image
          height={60}
          width={[1 / 4, 1 / 6]}
          mr={[2]}
          src={data.iconWhite.file.url}
          alt={data.iconWhite.file.fileName}
        ></Image>
        <Box width={[3 / 4, 5 / 6]}>
          <Flex justifyContent="space-between">
            <PackageText fontSize={['14px', '16px']} mr={[2, 0]}>
              {data.title}
            </PackageText>
            <PackageText fontSize={['14px', '16px']}>
              {data.currency}
              {data.price}
              {data.priceText}
            </PackageText>
          </Flex>
        </Box>
      </Flex>
    </StyledRectangle>
  );
};

export default PackageBanner;
